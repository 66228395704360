import * as React from 'react';
import HeaderComponent from '../../components/header/HeaderComponent';
import { GlobalStyle } from '../../styles/global';

const ContatoPage = () => {
  return (
    <main>
      <GlobalStyle />
      <title>
        Horizonte - Capacitação e geração de emprego no mercado de tecnologia.
      </title>
      <HeaderComponent />
      <section>
        <h1>contato</h1>
        <form
          name="Contact Form"
          method="POST"
          data-netlify="true"
          action="/thank-you/"
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <div>
            <label htmlFor="email">Your Email:</label>
            <input type="email" name="email" id="email" />
          </div>
          <div>
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" />
          </div>
          <button type="submit">Send</button>
        </form>
      </section>
    </main>
  );
};

export default ContatoPage;
